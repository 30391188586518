import React from 'react';
import PropTypes from 'prop-types';
import './Field.css';

const Field = ({ text }) => {
  return (
    <div className="title-container">
      <h2 className="club-title">{text}</h2>
    </div>
  );
};

Field.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Field;