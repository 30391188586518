import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC5i0wXrxNn9-ygxV3BeDx-3HUStVHzRRU",
  authDomain: "feb-sports.firebaseapp.com",
  databaseURL: "https://feb-sports-default-rtdb.firebaseio.com",
  projectId: "feb-sports",
  storageBucket: "feb-sports.appspot.com",
  messagingSenderId: "299305097147",
  appId: "1:299305097147:web:fe2d1b9ada4b0049de969e"

};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const secondaryApp = initializeApp(firebaseConfig, "Secondary");

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);
const secondaryAuth = getAuth(secondaryApp);


// Initialize Firestore and get a reference to the service
const firestore = getFirestore(app);

export { app, auth, secondaryAuth, firestore };