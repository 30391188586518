import React, { useState, useEffect } from 'react';
import GreyButton from '../../components/GreyButton/GreyButton';
import axios from 'axios';
import useAuthToken from '../../hooks/useAuthToken';

import './FichaActualizable.css';

const FichaActualizable = ({ jugador, handleDelete }) => {
  
  console.log(`FichaActu:${jugador.jugador_id}`)
  
  const apiUrl = process.env.NODE_ENV === 'production' 
  ? process.env.REACT_APP_API_URL_PROD 
    : process.env.REACT_APP_API_URL_DEV;

  const [representante, setRepresentante] = useState(''); // Estado para manejar el texto de observacion
  const [fotoUrl, setFotoUrl] = useState(''); // Estado para manejar la URL de la foto
  const [documentos, setDocumentos] = useState([]); // Estado para manejar los documentos
  const { token } = useAuthToken();


  const fetchRepresentante = async () => {
    try {
      const response = await axios.get(`${apiUrl}/representantes/delJugador/${jugador.jugador_id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const representanteEncontrado = response.data;

      if (representanteEncontrado) {
        setRepresentante(representanteEncontrado);
      } else {
        setRepresentante(null);
      }
    } catch (error) {
      console.error('Error fetching representante data:', error);
    }
  }

  const fetchFoto = async () => {
    try {
      const response = await axios.get(`${apiUrl}/documentacion/${jugador.documentacion_id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const documento = response.data;

      if (documento) {
        setDocumentos(documento);
      } else {
        setDocumentos([]);
      }

      if (documento && documento.foto) {
        setFotoUrl(documento.foto); // Ajusta esto según la estructura de tu respuesta
      } else {
        setFotoUrl('');
      }
    } catch (error) {
      console.error('Error fetching photo URL:', error);
    }
  };

  useEffect(() => {
    if (token) {
    if (jugador.jugador_id) {
      fetchRepresentante();
      fetchFoto(); // Fetch the photo URL when the jugador_id changes
    }
  }
  }, [jugador.jugador_id, token]);

  const handleFileChange = (event) => {
    // Maneja la selección de archivo aquí si es necesario
    const file = event.target.files[0];
    if (file) {
      console.log('Archivo seleccionado:', file);
      // Puedes manejar el archivo según sea necesario
    }
  };


  return (
    <div className="ficha">
      <div className="ficha-photo-container">
        {fotoUrl ? (
          <img src={fotoUrl} alt="Foto del Jugador" className="ficha-photo" />
        ) : (
          <p>No hay foto disponible</p>
        )}
      </div>
        {/*REPRESENTANTE*/}
        {representante && (
        <form className="ficha-form">
          <div className="ficha-section">
            <h3>Información del Representante</h3>
            <div className="ficha-form-group">
              <label>Nombres:</label>
              <input type="text" disabled value={representante.nombres_representante || ''} />
            </div>
            <div className="ficha-form-group">
              <label>Apellidos:</label>
              <input type="text" disabled value={representante.apellidos_representante || ''} />
            </div>
          </div>

          <div className="ficha-section">
            <div className="ficha-form-group">
              <label>Cédula:</label>
              <input type="text" disabled value={representante.cedula || ''} />
            </div>
            <div className="ficha-form-group">
              <label>Correo:</label>
              <input type="text" disabled value={representante.correo || ''} />
            </div>
          </div>
        </form>
      )}
        

        {/*JUGADOR*/}

        <form className="ficha-form">
        <div className="ficha-section">
          <h3>Información del Jugador</h3>
          <div className="ficha-form-group">
            <label>Nombres:</label>
            <input type="text" disabled value={jugador.nombres} />
          </div>
          <div className="ficha-form-group">
            <label>Apellidos:</label>
            <input type="text" disabled value={jugador.apellidos} />
          </div>
        </div>
        </form>

        <form className="ficha-form">
        <div className="ficha-section">
        <div className="ficha-form-group">
            <label>Cédula:</label>
            <input type="text" disabled value={jugador.cedula} />
          </div>
          <div className="ficha-form-group">
            <label>Nacimiento:</label>
            <input type="text" disabled value={jugador.fecha_de_nacimiento} />
          </div>
          </div>
        </form>

        <form className="ficha-form">
        <div className="ficha-section">
        <div className="ficha-form-group">
            <label>Posición:</label>
            <input type="text" disabled value={jugador.posicion} />
          </div>
          <div className="ficha-form-group">
            <label>Edad:</label>
            <input type="text" disabled value={jugador.edad} />
          </div>
        </div>
        </form>

        <form className="ficha-form">
        <div className="ficha-section">
        <div className="ficha-form-group">
            <label>Sexo:</label>
            <input type="text" disabled value={jugador.sexo} />
          </div>
          <div className="ficha-form-group">
            <label>Tipo de sangre:</label>
            <input type="text" disabled value={jugador.tipo_de_sangre} />
          </div>
        </div>
        </form>

        <form className="ficha-form">
        <div className="ficha-section">
        <div className="ficha-form-group">
            <label>País:</label>
            <input type="text" disabled value={jugador.pais} />
          </div>
          <div className="ficha-form-group">
            <label>Provincia:</label>
            <input type="text" disabled value={jugador.provincia} />
          </div>
        </div>
        </form>

        <form className="ficha-form">
        <div className="ficha-section">
        <div className="ficha-form-group">
            <label>Ciudad:</label>
            <input type="text" disabled value={jugador.ciudad} />
          </div>
          <div className="ficha-form-group">
            <label>Residencia:</label>
            <input type="text" disabled value={jugador.residencia} />
          </div>
        </div>
        </form>

        {/*CONTACTO*/}

        <form className="ficha-form">
        <div className="ficha-section">
          <h3>Información de Contacto</h3>
          <div className="ficha-form-group">
            <label>Dirección:</label>
            <input type="text" disabled value={jugador.direccion} />
          </div>
          <div className="ficha-form-group">
            <label>Teléfono:</label>
            <input type="text" disabled value={jugador.telefono} />
          </div>
        </div>
        </form>

        
        {/*DOCUMENTOS

        {documentos.length > 0 && (
        <div>
          <h3>Documentos:</h3>
          <ul>
            {documentos.map((doc) => (
              <li key={doc.id}>
                <a href={doc.url} target="_blank" rel="noopener noreferrer">{doc.nombre}</a>
              </li>
            ))}
          </ul>
        </div>
      )}

        <div className="rep-form-group-doc">
        <div className="form-item-doc">
            <label htmlFor="foto">Foto:</label>
            <input 
            type="file" 
            onChange={handleFileChange} 
            disabled={false} // Habilita el campo si es necesario
            />
        </div>
        </div>

        <div className="rep-form-group-doc">
        <div className="form-item-doc">
            <label htmlFor="ficha_firmada">Ficha firmada:</label>
            <input 
            type="file" 
            onChange={handleFileChange} 
            disabled={false} // Habilita el campo si es necesario
            />
        </div>
        </div>*/}
            


        <div className="ficha-button-group">
          {/*<GreyButton onClick={handleAccept}>ACTUALIZAR</GreyButton> Manejar contenido de los botones*/}
          <GreyButton onClick={handleDelete}>ELIMINAR</GreyButton>
        </div>
    </div>
  );
};

export default FichaActualizable;