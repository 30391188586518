import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from '../../../components/Header/Header';
import Field from '../../../components/Field/Field';
import TitleContainer from '../../../components/TitleContainer/TitleContainer';
import Documentos from '../../../components/Documentos/Documentos';
import axios from 'axios';
import useAuthToken from '../../../hooks/useAuthToken';

const RepVerDocumentos = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { jugador_id } = location.state || {};
  const { token } = useAuthToken();
  const [jugador, setJugador] = useState(null); // Estado para almacenar el jugador
  const [documentos, setDocumentos] = useState(null); // Estado para almacenar los documentos del jugador

  // Determinar la URL base de la API según el entorno
  const apiUrl = process.env.NODE_ENV === 'production' 
    ? process.env.REACT_APP_API_URL_PROD 
    : process.env.REACT_APP_API_URL_DEV;

  useEffect(() => {
    const fetchJugador = async () => {
      try {
        const responseJugador = await axios.get(`${apiUrl}/jugadores/${jugador_id}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setJugador(responseJugador.data); // Almacena los datos del jugador en el estado

        const documentos_id = responseJugador.data.documentacion_id;
        if (documentos_id) {
          const responseDocumentos = await axios.get(`${apiUrl}/documentacion/${documentos_id}`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          setDocumentos(responseDocumentos.data); // Almacena los documentos del jugador en el estado
        }
      } catch (error) {
        console.error('Error fetching player data:', error);
      }
    };
    if (token) {

    if (jugador_id) {
      fetchJugador();
    }
  }
  }, [jugador_id, token]);

  const handleBackClick = () => {
    navigate('/rep-ficha-inscrito', { state: { jugador_id } });
  };

  return (
    <div className="AdminVerDocumentos">
      <Header />
      <Field text="Jugadores" />
      <TitleContainer text="Documentos" onBackClick={handleBackClick} />
      {documentos && <Documentos documentos={documentos} />}
    </div>
  );
};

export default RepVerDocumentos;