import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase/firebase';

const HandleRootRedirect = ({ user }) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if ((location.pathname === '/' || location.pathname === '/rep-login') && user) {
      signOut(auth).then(() => {
        navigate("/"); // O la ruta que consideres adecuada después de cerrar sesión
      }).catch((error) => {
        console.error("Error al cerrar sesión:", error);
      });
    }
  }, [location.pathname, user, navigate]);

  /*useEffect(() => {
    if (user) {
      if (location.pathname === '/') {
        // Redirige al usuario a la ruta adecuada según su rol
        navigate(user.rol === 'admin' ? "/admin-principal" : "/rep-principal");
      } else if (location.pathname === '/admin-login' && user.rol === 'admin') {
        navigate("/admin-principal");
      } else if (location.pathname === '/rep-login' && user.rol === 'rep') {
        navigate("/rep-principal");
      }
    }
  }, [location.pathname, user, navigate]);*/

  return null;
};

export default HandleRootRedirect;
