import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, firestore } from '../../../firebase/firebase';
import { getDoc, doc } from 'firebase/firestore';
import Header from '../../../components/Header/Header';
import Field from '../../../components/Field/Field';
import TitleContainer from '../../../components/TitleContainer/TitleContainer';
import './RepIngresarJugador.css';
import GreyButton from '../../../components/GreyButton/GreyButton';
import axios from 'axios';
import provinciasData from '../../../data/provincias.json';
import useAuthToken from '../../../hooks/useAuthToken';

const RepIngresarJugador = () => {
  const [playerInfo, setPlayerInfo] = useState({
    representante: false,
    club_id: '',
    nombres: '',
    apellidos: '',
    fecha_de_nacimiento: '',
    nacionalidad: 'null',
    residencia: '',
    cedula: '',
    edad: '',
    sexo: '',
    tipo_de_sangre: '',
    estado: "Pendiente",
    pais: '',
    provincia: '',
    ciudad: '',
    estatura: 0,
    peso: 0,
    fecha_de_inscripcion: '2000/01/01',
    posicion: '',
    comentario: 'null',
    esLibre: false,
    licencia:'null',
    direccion: '',
    telefono: '',
    documentacion: {
      "cedula": null, //cambiar a cedula
      "ficha_firmada": null,
      "foto": null,
      "ficha_medica": null,
      "autorizacion_representante": null
    }
  });

  const fotoRef = useRef(null);
  const cedulaRef = useRef(null);
  const fichaFirmadaRef = useRef(null);
  const fichaMedicaRef = useRef(null);
  const autorizacionRepresentanteRef = useRef(null);

  const [repreInfo, setRepreInfo] = useState({
    nombres_representante: '',
    apellidos_representante: '',
    cedula: '',
    correo: '',
    telefono: '',
  });

  const [isAdult, setIsAdult] = useState(null); 
  const [provincias, setProvincias] = useState([]);
  const [cantones, setCantones] = useState([]);
  const { token } = useAuthToken();
  const navigate = useNavigate();

  // Determinar la URL base de la API según el entorno
  const apiUrl = process.env.NODE_ENV === 'production' 
    ? process.env.REACT_APP_API_URL_PROD 
    : process.env.REACT_APP_API_URL_DEV;

  useEffect(() => {
    const fetchUserClub = async () => {
      const user = auth.currentUser;

      if (user) {
        try {
          const userDoc = await getDoc(doc(firestore, 'usuarios', user.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            const nombre_club = userData.clubName || '';
            
            setPlayerInfo((prevInfo) => ({
              ...prevInfo,
              nombre_club: nombre_club,
            }));

            if (nombre_club) {
              const response = await axios.get(`${apiUrl}/clubes/nombre/${nombre_club}`, {
                headers: {
                  'Authorization': `Bearer ${token}`
                }
              });

              const clubId = response.data.club_id || 'N/A';

              setPlayerInfo((prevInfo) => ({
                ...prevInfo,
                club_id: clubId,
              }));
            }
          }
        } catch (error) {
          console.error('Error fetching user or club data:', error);
        }
      }
    };
    if (token) {
    fetchUserClub();
    }
  }, [apiUrl, token]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPlayerInfo({ ...playerInfo, [name]: value });

    if (name === 'pais' && value === 'Ecuador') {
        setProvincias(Object.keys(provinciasData)); // Obtener las provincias si el país es Ecuador
        setCantones([]);
    } else if (name === 'pais') {
        setProvincias([]);
        setCantones([]);
    } else if (name === 'provincia' && playerInfo.pais === 'Ecuador') {
        const selectedProvince = provinciasData[value] || {};
        setCantones(selectedProvince);
    }

  // Maneja el cambio en el campo de fecha de nacimiento
    if (name === 'fecha_de_nacimiento') {
      console.log('Fecha de nacimiento ingresada:', value);

      // Asegúrate de que el valor tenga el formato correcto (DD/MM/YYYY)
      const [year, month, day] = value.split('-');
      console.log('Día:', day, 'Mes:', month, 'Año:', year);

      if (year && month && day) {
        // Formato de fecha en YYYY-MM-DD para el cálculo
        const formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
        console.log('Fecha formateada:', formattedDate);

        // Calcula la edad
        const edad = calculateAge(formattedDate);
        console.log('Edad calculada:', edad);

        setPlayerInfo((prevState) => ({
          ...prevState,
          fecha_de_nacimiento: formattedDate,
          edad: edad
        }));
      } else {
        console.error('Formato de fecha incorrecto');
      }
    } else {
      setPlayerInfo((prevState) => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  // Calcula la edad basada en la fecha de nacimiento
  const calculateAge = (birthDate) => {
    console.log('Fecha de nacimiento para calcular edad:', birthDate);

    const today = new Date();
    const birthDateObj = new Date(birthDate);

    let age = today.getFullYear() - birthDateObj.getFullYear();
    const monthDifference = today.getMonth() - birthDateObj.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDateObj.getDate())) {
      age--;
    }

    console.log('Edad calculada:', age);
    return age;
  };
  const handleInputChangeRep = (e) => {
    const { name, value } = e.target;
    setRepreInfo({ ...repreInfo, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files && files.length > 0) {
      setPlayerInfo(prevInfo => ({
        ...prevInfo,
        documentacion: {
          ...prevInfo.documentacion,
          [name]: files[0]
        }
      }));
    }
  };
  

  const handleSave = async (event) => {
    event.preventDefault();
  
    try {
      if (isAdult === null) {
        alert("Por favor, seleccione si el jugador es mayor de edad.");
        return;
      }

      if (isAdult === false) {
        setPlayerInfo((prevState) => ({
          ...prevState,
          representante: true
        }));
        if (!repreInfo.nombres_representante || !repreInfo.apellidos_representante || !repreInfo.cedula) {
          alert("Por favor, complete los datos del representante legal.");
          return;
        }
      }

      const formData = new FormData();

      //const appendFileIfExists = (ref, fieldName) => {
      //  if (ref.current && ref.current.files.length > 0) {
      //    formData.append(fieldName, ref.current.files[0]);
      //  }
      //};

    //appendFileIfExists(fotoRef, 'foto');
    //appendFileIfExists(cedulaRef, 'ficha_sin_firmar');
    //appendFileIfExists(fichaFirmadaRef, 'ficha_firmada');
    //appendFileIfExists(fichaMedicaRef, 'ficha_medica');
    //appendFileIfExists(autorizacionRepresentanteRef, 'autorizacion_representante');

    for (const key in playerInfo) {
      if (key === 'documentacion') {
        for (const docKey in playerInfo.documentacion) {
          if (playerInfo.documentacion[docKey]) {
            formData.append(docKey, playerInfo.documentacion[docKey]);
          }
        }
      } else {
        formData.append(key, playerInfo[key]);
      }

    }
    console.log('Datos a enviar:', playerInfo);

      const jugadorResponse = await axios.post(`${apiUrl}/jugadores/create`, formData, {
        headers: {
          //'Content-Type': 'application/json; charset=utf-8',
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        },
      });

      const jugadorId = jugadorResponse.data.jugador_id;

      console.log('Datos a enviar:', repreInfo);

      if (isAdult === false) {
        await axios.post(`${apiUrl}/representantes/create`,
          {
            jugador_id: jugadorId,
            ...repreInfo,
          },
          {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }
        );
      }
      //Vaciar
      setPlayerInfo({
        ...playerInfo,
        nombres: '',
        apellidos: '',
        fecha_de_nacimiento: '',
        nacionalidad: '',
        residencia: '',
        cedula: '',
        edad: '',
        sexo: '',
        tipo_de_sangre: '',
        estado: "Pendiente",
        pais: '',
        provincia: '',
        ciudad: '',
        estatura: 0,
        peso: 0,
        fecha_de_inscripcion: '2000/01/01',
        posicion: '',
        comentario: '',
        esLibre: false,
        licencia:'',
        direccion: '',
        telefono: '',
        documentacion: {
          "ficha_sin_firmar": null,
          "ficha_firmada": null,
          "foto": null,
          "ficha_medica": null,
          "autorizacion_representante": null
        }
      });

      // Vaciar los campos de archivos
      if (fotoRef.current) fotoRef.current.value = null;
      if (cedulaRef.current) cedulaRef.current.value = null;
      if (fichaFirmadaRef.current) fichaFirmadaRef.current.value = null;
      if (fichaMedicaRef.current) fichaMedicaRef.current.value = null;
      if (autorizacionRepresentanteRef.current) autorizacionRepresentanteRef.current.value = null;

      setRepreInfo({
        nombres_representante: '',
        apellidos_representante: '',
        cedula: '',
        correo: '',
        telefono: ''
      });



      alert('Jugador guardado satisfactoriamente');
    } catch (error) {
      console.error("Error al guardar el jugador y representante:", error);
      alert('Error al guardar el jugador y representante. Inténtelo de nuevo.');
    }
  };

  const handleBackClick = () => {
    navigate('/rep-principal');
  };
  return (
    <div className="RepInscribir">
      <Header />
      <main className="RepInscribir-main">
        <Field text="Jugadores" />
        <TitleContainer text="Registro de jugador" onBackClick={handleBackClick} />
        <form className="inscribir-form" onSubmit={(e) => e.preventDefault()}>
        {/*  <div className="form-group">
            <div className="photo-upload">
              <button>Subir foto</button>
            </div>
          </div>*/}
          

          <div className="section">
            <h3>¿El jugador es mayor de edad?</h3>
            <div className="rep-form-group-doc">
              <label>
                <input
                  type="radio"
                  name="isAdult"
                  value="yes"
                  checked={isAdult === true}
                  onChange={() => setIsAdult(true)}
                />
                Sí
              </label>
              <label>
                <input
                  type="radio"
                  name="isAdult"
                  value="no"
                  checked={isAdult === false}
                  onChange={() => setIsAdult(false)}
                />
                No
              </label>
            </div>
          </div>

          {isAdult === false && (
            <div className="section">
            <h3>REPRESENTANTE LEGAL</h3>
            <div className="rep-form-group">
              <div className="form-item">
                <label htmlFor="nombres">Nombres:</label>
                <input
                  type="text"
                  id="nombres"
                  name="nombres_representante"
                  value={repreInfo.nombres_representante}
                  onChange={handleInputChangeRep}
                />
              </div>
              <div className="form-item">
                <label htmlFor="apellidos">Apellidos:</label>
                <input
                  type="text"
                  id="apellidos"
                  name="apellidos_representante"
                  value={repreInfo.apellidos_representante}
                  onChange={handleInputChangeRep}
                />
              </div>
              <div className="form-item">
                <label htmlFor="cedula">Cédula:</label>
                <input
                  type="text"
                  id="cedula"
                  name="cedula"
                  value={repreInfo.cedula}
                  onChange={handleInputChangeRep}
                />
              </div>
              <div className="form-item">
                <label htmlFor="correo">Email:</label>
                <input
                  type="text"
                  id="correo"
                  name="correo"
                  value={repreInfo.correo}
                  onChange={handleInputChangeRep}
                />
              </div>
              <div className="form-item">
                <label htmlFor="telefono">Teléfono:</label>
                <input
                  type="text"
                  id="telefono"
                  name="telefono"
                  value={repreInfo.telefono}
                  onChange={handleInputChangeRep}
                />
              </div>
            </div>
          </div>
          )}


          <div className="section">
            <h3>DATOS PERSONALES</h3>
            <div className="rep-form-group">
              <div className="form-item">
                <label htmlFor="club">Club:</label>
                <input
                  type="text"
                  id="club"
                  name="nombre_club"
                  value={playerInfo.nombre_club}
                  onChange={handleInputChange}
                  readOnly // Este campo será de solo lectura ya que lo estamos estableciendo desde Firebase
                />
              </div>
              <div className="form-item">
                <label htmlFor="nombres">Nombres:</label>
                <input
                  type="text"
                  id="nombres"
                  name="nombres"
                  value={playerInfo.nombres}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-item">
                <label htmlFor="apellidos">Apellidos:</label>
                <input
                  type="text"
                  id="apellidos"
                  name="apellidos"
                  value={playerInfo.apellidos}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="rep-form-group">
            <div className="form-item">
                <label htmlFor="cedula">Cédula:</label>
                <input
                  type="text"
                  id="cedula"
                  name="cedula"
                  value={playerInfo.cedula}
                  onChange={handleInputChange}
                />
              </div>
            <div className="form-item">
              <label htmlFor="fecha_de_nacimiento">Fecha de nacimiento:</label>
              <input
                type="date"
                id="fecha_de_nacimiento"
                name="fecha_de_nacimiento"
                value={playerInfo.fecha_de_nacimiento}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-item">
              <label htmlFor="edad">Edad:</label>
              <input
                type="text"
                id="edad"
                name="edad"
                value={playerInfo.edad || ''}
                readOnly
              />
            </div>
            </div>
            <div className="rep-form-group">
            
            <div className="form-item">
                <label htmlFor="posicion">Posición:</label>
                <input
                  type="text"
                  id="posicion"
                  name="posicion"
                  value={playerInfo.posicion}
                  onChange={handleInputChange}
                />
              </div>

              <div className="form-item">
                <label htmlFor="sexo">Género:</label>
                <select
                  id="sexo"
                  name="sexo"
                  value={playerInfo.sexo}
                  onChange={handleInputChange}
                >
                  <option value="">Seleccione un género</option>
                  <option value="Masculino">Masculino</option>
                  <option value="Femenino">Femenino</option>
                </select>
              </div>
              <div className="form-item">
                <label htmlFor="tipo_de_sangre">Tipo de sangre:</label>
                <select
                  id="tipo_de_sangre"
                  name="tipo_de_sangre"
                  value={playerInfo.tipo_de_sangre}
                  onChange={handleInputChange}
                >
                  <option value="">Seleccione un tipo</option>
                  <option value="O+">O+</option>
                  <option value="O-">O-</option>
                  <option value="A+">A+</option>
                  <option value="A-">A-</option>
                  <option value="B+">B+</option>
                  <option value="B-">B-</option>
                  <option value="AB+">AB+</option>
                  <option value="AB-">AB-</option>
                </select>
              </div>
            </div>      
            </div>


            <div className="rep-form-group">
            <div className="form-item">
                <label htmlFor="pais">País:</label>
                <select
                    id="pais"
                    name="pais"
                    value={playerInfo.pais}
                    onChange={handleInputChange}
                >
                    <option value="">Seleccione un país</option>
                    <option value="Ecuador">Ecuador</option>
                    <option value="Argentina">Argentina</option>
                    <option value="Perú">Perú</option>
                    <option value="Chile">Chile</option>
                    <option value="Colombia">Colombia</option>
                    {/* Agrega otros países si es necesario */}
                </select>
            </div>

        <div className="form-item">
            <label htmlFor="provincia">Provincia:</label>
            {playerInfo.pais === 'Ecuador' ? (
                <select
                    id="provincia"
                    name="provincia"
                    value={playerInfo.provincia}
                    onChange={handleInputChange}
                    disabled={!provincias.length}
                >
                    <option value="">Seleccione una provincia</option>
                    {provincias.map((provincia) => (
                        <option key={provincia} value={provincia}>
                            {provincia}
                        </option>
                    ))}
                </select>
            ) : (
                <input
                    type="text"
                    id="provincia"
                    name="provincia"
                    value={playerInfo.provincia}
                    onChange={handleInputChange}
                />
            )}
        </div>

        <div className="form-item">
            <label htmlFor="ciudad">Ciudad:</label>
            {playerInfo.pais === 'Ecuador' ? (
                <select
                    id="ciudad"
                    name="ciudad"
                    value={playerInfo.ciudad}
                    onChange={handleInputChange}
                    disabled={!Object.keys(cantones).length}
                >
                    <option value="">Seleccione una ciudad</option>
                    {Object.entries(cantones).map(([codigo, canton]) => (
                        <option key={codigo} value={canton}>
                            {canton}
                        </option>
                    ))}
                </select>
            ) : (
                <input
                    type="text"
                    id="ciudad"
                    name="ciudad"
                    value={playerInfo.ciudad}
                    onChange={handleInputChange}
                />
            )}
        </div>
    </div>


                  <div className="section">
            <h3>CONTACTO</h3>
            <div className="rep-form-group">
            <div className="form-item">
            <label htmlFor="residencia">Residencia:</label>
            <select
                id="residencia"
                name="residencia"
                value={playerInfo.residencia}
                onChange={handleInputChange}
            >
                <option value="">País de residencia</option>
                <option value="Ecuador">Ecuador</option>
                <option value="Argentina">Argentina</option>
                <option value="Perú">Perú</option>
                <option value="Chile">Chile</option>
                <option value="Colombia">Colombia</option>
                {/* Agrega otros países si es necesario 
                CAMBIAR A RESIDENCIA*/}
            </select>
        </div>
              <div className="form-item">
                <label htmlFor="direccion">Dirección:</label>
                <input
                  type="text"
                  id="direccion"
                  name="direccion"
                  value={playerInfo.direccion}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-item">
                <label htmlFor="telefono">Teléfono:</label>
                <input
                  type="text"
                  id="telefono"
                  name="telefono"
                  value={playerInfo.telefono}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>


          <div className="section">
            <h3>DOCUMENTACIÓN</h3>

            <div className="rep-form-group-doc">
              <div className="form-item-doc">
                <label htmlFor="foto">Foto:</label>
                <input
                  type="file"
                  id="foto"
                  name="foto"
                  ref={fotoRef}
                  onChange={handleFileChange}
                />
              </div>
            </div>

            <div className="rep-form-group-doc">
              <div className="form-item-doc">
                <label htmlFor="cedula">Cédula:</label>
                <input
                  type="file"
                  id="cedula"
                  name="cedula"
                  ref={cedulaRef}
                  onChange={handleFileChange}
                />
              </div>
            </div>

            <div className="rep-form-group-doc">
              <div className="form-item-doc">
                <label htmlFor="ficha_firmada">Ficha firmada:</label>
                <input
                  type="file"
                  id="ficha_firmada"
                  name="ficha_firmada"
                  ref={fichaFirmadaRef}
                  onChange={handleFileChange}
                />
              </div>
            </div>
            <div className="rep-form-group-doc">
              <div className="form-item-doc">
                <label htmlFor="ficha_medica">Ficha médica:</label>
                <input
                  type="file"
                  id="ficha_medica"
                  name="ficha_medica"
                  ref={fichaMedicaRef}
                  onChange={handleFileChange}
                />
              </div>
            </div>
            {isAdult === false && (
              <div className="rep-form-group-doc">
                <div className="form-item-doc">
                  <label htmlFor="autorizacion_representante">Autorización del representante:</label>
                  <input
                    type="file"
                    id="autorizacion_representante"
                    name="autorizacion_representante"
                    ref={autorizacionRepresentanteRef}
                    onChange={handleFileChange}
                  />
                </div>
              </div>
            )}
          </div>
          <GreyButton onClick={handleSave}>Guardar</GreyButton>
        </form>
      </main>
    </div>
  );
};
export default RepIngresarJugador;