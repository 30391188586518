import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from '../../../components/Header/Header';
import Field from '../../../components/Field/Field';
import TitleContainer from '../../../components/TitleContainer/TitleContainer';
import Ficha from '../../../components/Ficha/Ficha';
import Modal from '../../../components/Modal/Modal'; // Importa tu modal o componente para ingresar texto
import './AdminVerFicha.css';
import axios from 'axios';
import useAuthToken from '../../../hooks/useAuthToken';

const AdminVerFicha = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { jugador_id } = location.state || {};
  const { token } = useAuthToken();

   // Determinar la URL base de la API según el entorno
   const apiUrl = process.env.NODE_ENV === 'production' 
   ? process.env.REACT_APP_API_URL_PROD 
     : process.env.REACT_APP_API_URL_DEV;


  const [jugador, setJugador] = useState({
    jugador_id: '',
    nombres: '',
    apellidos: '',
    cedula: '',
    fecha_de_nacimiento: '',
    nacionalidad: '',
    edad: '',
    sexo: '',
    tipo_de_sangre: '',
    pais: '',
    provincia: '',
    ciudad: '',
    direccion: '',
    telefono: '',
    observacion: '' // Agrega el campo de observacion en el estado del jugador
  });

  const [observacion, setObservacion] = useState(''); // Estado para manejar el texto de observacion
  const [showModal, setShowModal] = useState(false); // Estado para controlar la visibilidad del modal

  useEffect(() => {
    const fetchJugador = async () => {
      try {
        const response = await axios.get(`${apiUrl}/jugadores/${jugador_id}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const jugadorEncontrado = response.data;
        if (jugadorEncontrado) {
          setJugador(jugadorEncontrado);
        }
      } catch (error) {
        console.error('Error fetching player data:', error);
      }
    };
    if (token) {
    if (jugador_id) {
      fetchJugador();
    }
  }
  }, [jugador_id, token]);
  

  const handleBackClick = () => {
    navigate('/admin-ver-solicitudes');
  };

  const handleViewDocuments = () => {
    navigate('/admin-ver-documentos', { state: { jugador_id } });
  };

  const handleAccept = async (event) => {
    event.preventDefault();
    try {
      // Realizar la actualización del estado del jugador
      await axios.put(`${apiUrl}/jugadores/update/${jugador_id}`,
        { estado: 'Inscrito' }, // Cuerpo de la solicitud
        { // Opciones de la solicitud
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );

      // Mostrar mensaje de alerta
      alert('Jugador ingresado correctamente');

      navigate('/admin-ver-solicitudes');
    } catch (error) {
      console.error('Error al actualizar jugador:', error);
      alert('Hubo un error al intentar ingresar al jugador');
    }
  };

  const handleDeny = (event) => {
    event.preventDefault();
    setShowModal(true); // Mostrar el modal para ingresar la observación
  };

  const handleSubmitDeny = async (observacion) => {
    try {
      // Realizar la actualización del estado del jugador a "Negado" con la observación
      await axios.put(`${apiUrl}/jugadores/update/${jugador_id}`,
        { estado: 'Negado',
          observacion: observacion},
        { // Opciones de la solicitud
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
  
      // Mostrar mensaje de alerta
      alert('Jugador denegado correctamente');
  
      // Cerrar el modal y limpiar el estado de observacion
      setObservacion('');
      setShowModal(false); // Ocultar el modal después de enviar la observacion
      navigate('/admin-ver-solicitudes');
    } catch (error) {
      console.error('Error al denegar jugador:', error);
      alert('Hubo un error al intentar denegar al jugador');
    }
  };

  const handleCloseModal = () => {
    setShowModal(false); // Cerrar el modal sin hacer ninguna acción
  };

  return (
    <div className="admin-ver-ficha">
      <Header />
      <Field text="Jugadores" />
      <TitleContainer text="Ficha del Jugador" onBackClick={handleBackClick} />
      <main className="admin-ver-ficha-main">
        <Ficha 
          jugador={jugador}
          handleViewDocuments={handleViewDocuments}
          handleAccept={handleAccept}
          handleDeny={handleDeny}
        />
      </main>
        <Modal isOpen={showModal} onClose={handleCloseModal} onSubmit={handleSubmitDeny} />
      </div>
  );
};

export default AdminVerFicha;