import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from '../../../components/Header/Header';
import Field from '../../../components/Field/Field';
import TitleContainer from '../../../components/TitleContainer/TitleContainer';
import FichaActualizable from '../../../components/FichaActualizable/FichaActualizable';
import './RepActualizarJugador.css';
import axios from 'axios';
import useAuthToken from '../../../hooks/useAuthToken';


const RepActualizarJugador = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { jugador } = location.state || {};
  const { token } = useAuthToken();


  const apiUrl = process.env.NODE_ENV === 'production' 
  ? process.env.REACT_APP_API_URL_PROD 
    : process.env.REACT_APP_API_URL_DEV;

  const [jugadorData, setJugadorData] = useState(jugador || {});
  const [observacion, setObservacion] = useState('');
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (jugador) {
      setJugadorData(jugador);
    }
  }, [jugador]);

  const handleBackClick = () => {
    navigate('/rep-solicitudes-enviadas');
  };


  /*const handleUpdate = async (event) => {
    event.preventDefault();
    try {
      await axios.put(`${apiUrl}/jugadores/update/${jugadorData.jugador_id}`, {
        estado: 'Pendiente'
      });

      alert('Jugador actualizado correctamente');
      navigate('/admin-ver-solicitudes');
    } catch (error) {
      console.error('Error al actualizar jugador:', error);
      alert('Hubo un error al intentar actualizar al jugador');
    }
  };*/

  const handleDelete = async (event) => {
    event.preventDefault();
    console.log('Fuera del try');
    try {
      console.log('Dentro del try antes del axios');

      await axios.delete(`${apiUrl}/jugadores/delete/${jugadorData.jugador_id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
        
      }
    
    );
      console.log('Despues del try');
  
      alert('Jugador eliminado correctamente');
      setObservacion('');
      navigate('/rep-solicitudes-enviadas');
    } 
    
    catch (error) {
      console.error('Error al eliminar jugador:', error);
      alert('Hubo un error al intentar eliminar el jugador');
    }
  };

  return (
    <div className="admin-ver-ficha">
      <Header />
      <Field text="Jugadores" />
      <TitleContainer text="Eliminar Ficha del Jugador" onBackClick={handleBackClick} />
      <main className="admin-ver-ficha-main">
        <FichaActualizable
          jugador={jugadorData}
          //handleUpdate={handleUpdate}
          handleDelete={handleDelete}
        />
      </main>
    </div>
  );
};

export default RepActualizarJugador;
