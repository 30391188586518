import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../../components/Header/Header';
import Field from '../../../components/Field/Field';
import TitleContainer from '../../../components/TitleContainer/TitleContainer';
import PlayersList from '../../../components/PlayersList/PlayersList';
import './RepListaJugadores.css';
import { auth, firestore } from '../../../firebase/firebase';
import { getDoc, doc } from 'firebase/firestore';
import axios from 'axios';
import useAuthToken from '../../../hooks/useAuthToken';

const RepListaJugadores = () => {
  const [jugadores, setJugadores] = useState([]);
  const navigate = useNavigate();
  const { token } = useAuthToken();
  // Determinar la URL base de la API según el entorno
  const apiUrl = process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_URL_PROD
    : process.env.REACT_APP_API_URL_DEV;

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Obtener el nombre del club del usuario desde Firebase
        const user = auth.currentUser;
        if (user) {
          const userDoc = await getDoc(doc(firestore, 'usuarios', user.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            const nombre_club = userData.clubName || '';

            // Realizar la solicitud al servidor para obtener el club_id
            const responseClub = await axios.get(`${apiUrl}/clubes/nombre/${nombre_club}`, {
              headers: {
                'Authorization': `Bearer ${token}`
              }
            });

            const clubId = responseClub.data.club_id || 'N/A';

            // Realizar la solicitud al servidor para obtener los jugadores del club
            const responseJugadores = await axios.get(`${apiUrl}/jugadores/delClub/${clubId}`, {
              headers: {
                'Authorization': `Bearer ${token}`
              }
            });

            const allJugadores = responseJugadores.data;

            // Filtrar los jugadores por club_id y estado 'Inscrito'
            const filteredJugadores = allJugadores.filter(jugador => {
              return jugador.club_id === clubId && jugador.estado === 'Inscrito';
            });

            // Actualizar el estado de jugadores con los jugadores filtrados
            setJugadores(filteredJugadores);
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (token) {
      fetchData();
    }
  }, [token]);

  const handleBackClick = () => {
    navigate('/rep-principal');
  };

  const handleViewFicha = (jugador_id) => {
    navigate('/rep-ficha-inscrito', { state: { jugador_id } });
  };

  return (
    <div className="AdminAsignarRol">
      <Header />
      <Field text="Jugadores" />
      <TitleContainer text="Ver jugadores inscritos" onBackClick={handleBackClick} />
      <PlayersList jugadores={jugadores} handleViewFicha={handleViewFicha} />
    </div>
  );
};

export default RepListaJugadores;