import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import './Roles.css';

const Roles = ({ roles }) => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate('/admin-ver-ficha');
  };

  return (
    <table className="roles-table">
      <thead>
        <tr>
          <th>Representante</th>
          <th>Club</th>
          <th>Correo</th>
        </tr>
      </thead>
      <tbody>
        {roles.map((roles, index) => (
          <tr key={index}>
            <td>{roles.repreName}</td>
            <td>{roles.clubName}</td>
            <td>{roles.email}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

Roles.propTypes = {
    roles: PropTypes.arrayOf(
    PropTypes.shape({
      representante: PropTypes.string.isRequired,
      club: PropTypes.string.isRequired,
      correo: PropTypes.string.isRequired
    })
  ).isRequired
};

export default Roles;