import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import Header from "../../../components/Header/Header";
import Button from "../../../components/Button/Button";
import "./AdminLogIn.css";

import { auth } from "../../../firebase/firebase";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";

const firestore = getFirestore();

const AdminLogIn = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleLogInClick = async (event) => {
    event.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Verifica el rol del usuario
      const docRef = doc(firestore, `usuarios/${user.uid}`);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const userData = docSnap.data();
        if (userData.rol === 'admin') {
          navigate('/admin-principal'); // Redirige a la página principal de administrador
        } else {
          await signOut(auth); // Cierra sesión si no es admin
          setError("No tienes permiso para acceder a esta área.");
        }
      } else {
        await signOut(auth); // Cierra sesión si el usuario no existe
        setError("El usuario no existe en la base de datos.");
      }
    } catch (error) {
      setError("Error al iniciar sesión. Verifique su email y contraseña.");
    }
  };

  return (
    <div className="AdminLogIn">
      <Header />
      <main className="AdminLogIn-main">
        <h2>Inicio de sesión</h2>
        <form className="login-form" onSubmit={handleLogInClick}>
          <div className="form-group">
            <label htmlFor="email">Correo:</label>
            <input
              type="text"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Contraseña:</label>
            <input
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          {error && <p className="error">{error}</p>}
          <Button className="button" type="submit">Ingresar</Button>
        </form>
      </main>
    </div>
  );
};

export default AdminLogIn;
