import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../../components/Header/Header';
import Field from '../../../components/Field/Field';
import './AdminAsignarRol.css';
import TitleContainer from '../../../components/TitleContainer/TitleContainer';
import GreyButton from '../../../components/GreyButton/GreyButton';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { firestore, secondaryAuth } from '../../../firebase/firebase';

const AdminAsignarRol = () => {
  const [rolInfo, setRolInfo] = useState({
    repreName: '',
    clubName: '',
    email: '',
    password: ''
  });

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRolInfo({ ...rolInfo, [name]: value });
  };

  const handleBackClick = () => {
    navigate('/admin-principal');
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await createUserWithEmailAndPassword(secondaryAuth, rolInfo.email, rolInfo.password);
      const user = userCredential.user;

      await setDoc(doc(firestore, `usuarios/${user.uid}`), {
        repreName: rolInfo.repreName,
        clubName: rolInfo.clubName,
        email: rolInfo.email,
        rol: 'rep',
        passwordChanged: false
      });

      // Cerrar sesión del usuario recién creado en la instancia secundaria
      await secondaryAuth.signOut();

      // Mostrar mensaje de éxito
      alert('Rol asignado satisfactoriamente');
      
      // Redirigir a la página de administración
      navigate('/admin-ver-asignados');
    } catch (error) {
      console.error('Error al crear usuario', error.message);
      alert('Rol no pudo ser creado');
    }
  };

  return (
    <div className="AdminAsignarRol">
      <Header />
      <Field text="Roles" />
      <TitleContainer text="Asignar Rol" onBackClick={handleBackClick} />
      <form className="asignar-form" onSubmit={handleSave}>
        <div className="form-group">
          <label htmlFor="repreName">Nombre:</label>
          <input
            type="text"
            id="repreName"
            name="repreName"
            value={rolInfo.repreName}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="clubName">Club:</label>
          <input
            type="text"
            id="clubName"
            name="clubName"
            value={rolInfo.clubName}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Correo:</label>
          <input
            type="text"
            id="email"
            name="email"
            value={rolInfo.email}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Contraseña:</label>
          <input
            type="password"
            id="password"
            name="password"
            value={rolInfo.password}
            onChange={handleInputChange}
          />
        </div>
        <GreyButton type="submit">Guardar</GreyButton>
      </form>
    </div>
  );
};

export default AdminAsignarRol;
