import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import Header from "../../../components/Header/Header";
import Button from "../../../components/Button/Button";
import { auth } from "../../../firebase/firebase";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import "./RepLogIn.css";

const firestore = getFirestore();

const RepLogIn = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleLogInClick = async (event) => {
    event.preventDefault();
    console.log("Handling login click...");
    console.log("Email:", email);
    console.log("Password:", password);
    
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password)
      .then((userCred)=> {
        userCred.user.getIdToken().then((idToken)=>{
          console.log(idToken);
        });
      })
      .catch((error) => {
        console.error(error);
      });
      const user = userCredential.user;

      const docRef = doc(firestore, `usuarios/${user.uid}`);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const userData = docSnap.data();

        if (userData.rol === 'rep') {
          if (userData.passwordChanged === false) {
            navigate('/rep-cambio-pass');
          } else {
            navigate('/rep-principal');
          }
        } else {
          await signOut(auth); // Cierra sesión si el rol no es 'rep'
          setError("Acceso no autorizado");
        }
      } else {
        await signOut(auth); // Cierra sesión si el usuario no existe
        setError("El usuario no existe en la base de datos.");
      }
    } catch (error) {
      console.error("Error during login:", error);
      setError("Error al iniciar sesión. Verifique su email y contraseña.");
    }
  };

  return (
    <div className="RepLogIn">
      <Header />
      <main className="RepLogIn-main">
        <h2>Inicio de sesión</h2>
        <form className="login-form" onSubmit={handleLogInClick}>
          <div className="form-group">
            <label htmlFor="email">Correo:</label>
            <input
              type="text"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Contraseña:</label>
            <input
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          {error && <p className="error">{error}</p>}
          <Button className="button" type="submit">Ingresar</Button>
        </form>
      </main>
    </div>
  );
};

export default RepLogIn;