// src/components/Header/Header.js
import React from 'react';
import './Header.css';
import feb from '../../assets/logos/feb.jpeg';
import fiba from '../../assets/logos/fiba.jpeg';
import ecuador from '../../assets/logos/ecuador.jpeg';

const Header = () => {
  return (
    <header className="App-header">
      <div className="top-section">
        <div className="logos">
          <img src={feb} alt="FEB Logo" className="feb-logo" />
          <img src={fiba} alt="FIBA Logo" className="fiba-logo" />
        </div>
      </div>
      <div className="bottom-section">
        <h1>Federación Ecuatoriana de Basketball</h1>
        <img src={ecuador} alt="Ecuador Logo" className="ecuador-logo" />
      </div>
    </header>
  );
};

export default Header;