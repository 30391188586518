import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Header from '../../../components/Header/Header';
import Field from '../../../components/Field/Field';
import GreyButton from '../../../components/GreyButton/GreyButton';
import ClubList from '../../../components/ClubList/ClubList';
import './AdminVerInscritos.css';
import TitleContainer from '../../../components/TitleContainer/TitleContainer';
import useAuthToken from '../../../hooks/useAuthToken';

const AdminVerInscritos = () => {
  const [clubs, setClubs] = useState([]);
  const navigate = useNavigate();
  const { token } = useAuthToken();
  // Determinar la URL base de la API según el entorno
  const apiUrl = process.env.NODE_ENV === 'production' 
  ? process.env.REACT_APP_API_URL_PROD 
    : process.env.REACT_APP_API_URL_DEV;

  useEffect(() => {
    const fetchClubs = async () => {
      try {
        const response = await axios.get(`${apiUrl}/clubes`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setClubs(response.data);
      } catch (error) {
        console.error('Error fetching clubs:', error);
      }
    };
    if (token) {
    fetchClubs();
    }
  }, [token]);

  const handleBackClick = () => {
    navigate('/admin-principal');
  };

  const handleClubClick = (clubName) => {
    navigate('/admin-ver-jugadores', { state: { clubName } });
  };

  const handleAddClubClick = () => {
    navigate('/admin-inscribir');
  };

  return (
    <div className="AdminVerInscritos">
      <Header />
      <Field text="jugadores" />
      <TitleContainer text="Clubes" onBackClick={handleBackClick} />
      <ClubList clubs={clubs} handleClubClick={handleClubClick} />
      <GreyButton onClick={handleAddClubClick} children={'Agregar Club'}/>
    </div>
  );
};

export default AdminVerInscritos;