import React from 'react';
import PropTypes from 'prop-types';
import defaultLogo from '../../assets/icons/shield.png'; // Asegúrate de tener una imagen por defecto en esta ruta
import './Club.css';

const Club = ({ name, logo, handleClubClick }) => {
  return (
    <div className="club" onClick={() => handleClubClick(name)}>
      <img
        src={logo ? logo : defaultLogo} // Usa el logo del club si está disponible, de lo contrario, usa la imagen por defecto
        alt="Club Logo"
        className="club-icon"
      />
      <p className="club-name">{name}</p>
    </div>
  );
};

Club.propTypes = {
  name: PropTypes.string.isRequired,
  logo: PropTypes.string, // Acepta una cadena de texto para la URL del logo
  handleClubClick: PropTypes.func.isRequired,
};

export default Club;
