// src/hooks/useAuthToken.js
import { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import axios from 'axios';

const useAuthToken = () => {
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchToken = async () => {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        try {
          const idToken = await user.getIdToken();
          const apiUrl = process.env.NODE_ENV === 'production'
            ? process.env.REACT_APP_API_URL_PROD
            : process.env.REACT_APP_API_URL_DEV;

          const loginResponse = await axios.post(`${apiUrl}/token/login`, { idToken });
          const { token } = loginResponse.data;

          setToken(token);
        } catch (error) {
          setError(error);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
        setError(new Error('Usuario no autenticado'));
      }
    };

    fetchToken();
  }, []);

  return { token, loading, error };
};

export default useAuthToken;