import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Header from '../../../components/Header/Header';
import Field from '../../../components/Field/Field';
import TitleContainer from '../../../components/TitleContainer/TitleContainer';
import SolicitudesPendientes from '../../../components/SolicitudesPendientes/SolicitudesPendientes';
import { auth, firestore } from '../../../firebase/firebase';
import { getDoc, doc } from 'firebase/firestore';
import './RepSolicitudesEnviadas.css';
import useAuthToken from '../../../hooks/useAuthToken';

const RepSolicitudesEnviadas = () => {
  const navigate = useNavigate();
  const [solicitudes, setSolicitudes] = useState([]);
  const { token } = useAuthToken();

  const apiUrl = process.env.NODE_ENV === 'production' 
  ? process.env.REACT_APP_API_URL_PROD 
    : process.env.REACT_APP_API_URL_DEV;

  const handleBackClick = () => {
    navigate('/rep-principal');
  };

  const handleViewFicha = (solicitud) => {
    navigate('/rep-actualizar-jugador', { state: { jugador: solicitud } });
  };

  useEffect(() => {
    const fetchSolicitudes = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          const userDoc = await getDoc(doc(firestore, 'usuarios', user.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            const nombre_club = userData.clubName || '';

            const responseClub = await axios.get(`${apiUrl}/clubes/nombre/${nombre_club}`, {
              headers: {
                'Authorization': `Bearer ${token}`
              }
            });

            const clubId = responseClub.data.club_id || 'N/A';

            const responseJugadores = await axios.get(`${apiUrl}/jugadores/delClub/${clubId}`, {
              headers: {
                'Authorization': `Bearer ${token}`
              }
            });

            const allJugadores = responseJugadores.data;

            const filteredJugadores = allJugadores.filter(jugador => jugador.estado === 'Pendiente' || jugador.estado === 'Negado');
            const sortedJugadores = filteredJugadores.sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt));
            
            setSolicitudes(sortedJugadores);
          }
        }
      } catch (error) {
        console.error('Error fetching players:', error);
      }
    };

    if (token) {
      fetchSolicitudes();
    }
  }, [token]);

  return (
    <div className="AdminAsignarRol">
      <Header />
      <Field text="Jugadores" />
      <TitleContainer text="Ver solicitudes pendientes" onBackClick={handleBackClick} />
      <SolicitudesPendientes solicitudes={solicitudes} handleViewFicha={handleViewFicha} />
    </div>
  );
};

export default RepSolicitudesEnviadas;