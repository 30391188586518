import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header/Header';
import Button from '../components/Button/Button';
import userIcon from '../assets/icons/usuario.png'; 
import './Inicio.css';

const Inicio = () => {
  const navigate = useNavigate();

  //const handleAdminClick = () => {
  // navigate('/admin-login');
  //};
  const handleRepClick = () => {
    navigate('/rep-login');
  };

  return (
    <div className="Inicio">
      <Header />
      <main className="Inicio-main">
        <div className="user-icon">
          <img src={userIcon} alt="User Icon" />
        </div>
        <div className="buttons">
          {/*<Button className="button" onClick={handleAdminClick}>Administrador</Button>*/}
          <Button className="button" onClick={handleRepClick}>Iniciar sesión</Button>
        </div>
      </main>
    </div>
  );
};

export default Inicio;