import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Header from '../../../components/Header/Header';
import Field from '../../../components/Field/Field';
import TitleContainer from '../../../components/TitleContainer/TitleContainer';
import Solicitudes from '../../../components/Solicitudes/Solicitudes';
import './AdminVerSolicitudes.css';
import useAuthToken from '../../../hooks/useAuthToken';

const AdminVerSolicitudes = () => {
  const navigate = useNavigate();
  const [solicitudes, setSolicitudes] = useState([]);
  const { token } = useAuthToken();
  // Determinar la URL base de la API según el entorno
  const apiUrl = process.env.NODE_ENV === 'production' 
  ? process.env.REACT_APP_API_URL_PROD 
    : process.env.REACT_APP_API_URL_DEV;

  const handleBackClick = () => {
    navigate('/admin-principal');
  };

  const handleViewFicha = (jugador_id) => {
    navigate('/admin-ver-ficha', { state: { jugador_id } });
  };

  useEffect(() => {
    const fetchSolicitudes = async () => {
      try {
        const response = await axios.get(`${apiUrl}/jugadores/conEstado/Pendiente`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        setSolicitudes(response.data);
      } catch (error) {
        console.error('Error fetching players:', error);
      }
    };
    if (token) {
    fetchSolicitudes();
    }
  }, [token]);

  return (
    <div className="AdminAsignarRol">
      <Header />
      <Field text="Jugadores" />
      <TitleContainer text="Ver solicitudes pendientes" onBackClick={handleBackClick} />
      <Solicitudes solicitudes={solicitudes} handleViewFicha={handleViewFicha} />
    </div>
  );
};

export default AdminVerSolicitudes;